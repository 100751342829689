import { isCallAgent, isTeamMember } from "lib/clientauthorizer";
import useUser from "lib/useUser";
import { useEffect, useState } from "react";
import MobileDialog from "./utilities/mobiledialog";
import { MdOutlineClose, MdOutlineExpand } from "react-icons/md";
import Button from "./utilities/button";
import PopupCall from "./popup_call";

export default function Popup() {
    const { user } = useUser()
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [minimized, setMinimized] = useState(false)
    const [question, setQuestion] = useState({})
    const [seconds, setSeconds] = useState(60)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (isTeamMember(user)) {
            setInterval(() => {
                fetch('/api/auth/authy/quiz').then(response => response.json())
                    .then(data => {
                        if (data?.success) {
                            setQuestion(data.question)
                            setIsPopupOpen(true)
                            setSeconds(60)
                            const audio = new Audio('/interface.wav');
                            audio.play();
                            const speech = new Audio('/hac-alert.mp3');
                            speech.play();
                        }
                    })
            }, 150000)
        }

    }, [user])

    useEffect(() => {
        setTimeout(() => {
            if (seconds > 0) {
                setSeconds((prev) => {
                    const secs = prev
                    return secs - 1
                })
            }
        }, 1000)
    }, [seconds]);

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        fetch('/api/auth/authy/submit_answer', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ answer: e.target.radio.value })
        }).then(response => response.json())
            .then(data => {
                setIsPopupOpen(false)
                setLoading(false)
            })
    }

    if (isCallAgent(user)) {
        return <>
            {isPopupOpen ? <div>
                <MobileDialog open={!minimized} setOpen={() => setMinimized(false)} onClose={() => {
                    if (seconds > 0)
                        setMinimized(true)
                    else {
                        setIsPopupOpen(false)
                    }
                }}>
                    <div className="p-4">
                        <MdOutlineClose className="text-2xl float-right cursor-pointer" onClick={() => setMinimized(true)} />
                        {seconds > 0 ? <form className="flex flex-col" onSubmit={handleSubmit}>
                            <div className="text-2xl font-bold text-center">You have <span className="text-red-800">{seconds} Seconds</span> to answer</div>
                            <div className="text-xl mt-6 font-medium">{question.question}</div>
                            <div className="flex flex-col mt-6">
                                {question.options?.split(',').map((option, index) =>
                                    <div class="flex items-center mb-4">
                                        <input id={`option-${index}`} type="radio" value={index + 1} name="radio" class="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300" />
                                        <label htmlFor={`option-${index}`} class="ml-2 text-lg font-medium text-gray-900">{option}</label>
                                    </div>
                                )}
                            </div>
                            <Button className="mt-6" type="submit" loading={loading} disabled={loading}>Submit</Button>
                        </form> : <div className="flex flex-col">
                            <div className="text-2xl font-bold text-center mb-4">Oops! Time's Up!</div>
                            <p className="mb-1">Please be informed that your non-compliance is being recorded and will negatively impact your overall performance score.</p>
                            <p>It is imperative to be consistently present on your screen during work hours and respond promptly to all popups.</p>
                        </div>}
                    </div>
                </MobileDialog>
                {seconds > 0 ? <div className="fixed bottom-0 right-0 mb-4 mr-4" onClick={() => setMinimized(false)}>
                    <button className="bg-red-600 text-white rounded w-[40vw] h-20 flex items-center justify-center" >
                        <MdOutlineExpand className="text-2xl" />
                        <div className="text-2xl font-bold text-center">You have {seconds} Seconds to answer</div>
                    </button>
                </div> : null}
            </div> : null}
            <PopupCall />
        </>
    } else {
        return null
    }
}