import { isCallAgent, isTeamMember } from "lib/clientauthorizer";
import useUser from "lib/useUser";
import { useCallback, useEffect, useRef, useState } from "react";
import { IoMdCall } from "react-icons/io";
import { FaTimes } from "react-icons/fa";
import { MdCallEnd } from "react-icons/md";
import { FiCopy } from "react-icons/fi";
import { getSocket } from "lib/socket";


export default function PopupCall() {
    const { user } = useUser()
    const [callStatus, setCallStatus] = useState({})
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const draggableRef = useRef(null);
    const [audio] = useState(new Audio('/nokia-tune.mp3'));

    // Function to play audio
    const playAudio = useCallback(() => {
        audio.play();
    }, [audio]);

    useEffect(() => {
        const handlePointerMove = (e) => {
            if (isDragging) {
                // Calculate new position
                const newX = position.x + e.movementX;
                const newY = position.y + e.movementY;

                // Get viewport dimensions
                const viewportWidth = window.innerWidth;
                const viewportHeight = window.innerHeight;

                // Calculate element dimensions
                const elementWidth = draggableRef.current.offsetWidth;
                const elementHeight = draggableRef.current.offsetHeight;

                // Constrain position within viewport boundaries
                const constrainedX = Math.min(0, Math.max(newX, elementWidth - viewportWidth));
                const constrainedY = Math.min(0, Math.max(newY, elementHeight - viewportHeight));
                console.log(newX, newY, constrainedX, constrainedY)
                setPosition({ x: constrainedX, y: constrainedY });
            }
        };

        const handlePointerUp = () => {
            if (isDragging) {
                setIsDragging(false);
                document.body.style.userSelect = "auto"; // Re-enable text selection
            }
        };

        // Attach global listeners for pointermove and pointerup
        window.addEventListener("pointermove", handlePointerMove);
        window.addEventListener("pointerup", handlePointerUp);

        // Cleanup function to remove the listeners when the component is unmounted
        return () => {
            window.removeEventListener("pointermove", handlePointerMove);
            window.removeEventListener("pointerup", handlePointerUp);
        };
    }, [isDragging, position]);

    const handlePointerDown = (e) => {
        setIsDragging(true);
        document.body.style.userSelect = "none"; // Prevent text selection globally
    };

    useEffect(() => {
        let socket;

        const initializeSocket = async () => {
            socket = await getSocket(); // Get or initialize the WebSocket connection

            socket.on('connect', () => {
                console.log('Connected to WebSocket:', socket.id);
                socket.emit('registerAgent', user.mobile);
            });

            socket.on('updateCallStatus', (data) => {
                setCallStatus(data);
                if (data.Status === 'busy') {
                    playAudio();
                }
            });

            socket.on('verifyConnection', (data, ack) => {
                console.log('Received connection verification request');
                ack(true); // Acknowledge the server that the client is alive and receiving messages
            });
        };

        if (isTeamMember(user)) {
            initializeSocket();
        }

        // Cleanup on component unmount
        return () => {
            if (socket) {
                socket.disconnect();
            }
        };
    }, [user]);

    console.log('callStatus', callStatus)

    if (isCallAgent(user)) {
        return callStatus?.Status ? <div ref={draggableRef} className="fixed bottom-0 right-0 mb-4 mr-4 cursor-move" onClick={() => { }}
            onPointerDown={handlePointerDown}
            style={{
                transform: `translate(${position.x}px, ${position.y}px)`,
                zIndex: 10000000,
            }}>
            <div className="bg-gray-600 text-white rounded-xl w-[20vw] p-4">
                <div className="flex items-center justify-between space-x-4 mb-4">
                    <div className="flex items-center space-x-4">
                        <div className={`w-8 h-8 rounded-full flex items-center justify-center ${callStatus?.Status === 'free' ? 'bg-red-600' : 'bg-blue-600'}`}>
                            {callStatus?.Status === 'free' ? <MdCallEnd className="text-2xl" /> : <IoMdCall className="text-2xl" />}
                        </div>
                        <div>
                            <div className="flex items-center space-x-2 cursor-pointer" onClick={() => navigator.clipboard.writeText(parseInt(callStatus?.From, 10).toString())}>
                                <p className="text-gray-200">{'+91' + parseInt(callStatus?.From, 10).toString()}</p>
                                <FiCopy />
                            </div>
                            <p className="text-xs text-gray-300">{callStatus?.user?.name}</p>
                        </div>
                    </div>
                    {callStatus?.Status === 'free' ? <FaTimes onClick={() => setCallStatus({})} className="text-3xl cursor-pointer" /> : null}
                </div>
                {callStatus?.user?.packages?.length > 0 ? <div className="mt-6 pb-6 border-b border-gray-500">
                    <p>Host For:</p>
                    {callStatus?.user?.packages?.map((pack, index) => <a href={`/${pack.uname}`} target="_blank" className="flex items-center space-x-2 mt-2" key={index}>
                        <div className="text-xs">{pack.name}</div>
                        <div className="bg-gray-500 rounded-full px-2 py-1 text-xs">{pack.fancyname}</div>
                    </a>)}
                </div> : null}
                <div className="flex space-x-2 mt-6">
                    <a href={`/backoffice/booking/search?date=&name=${parseInt(callStatus?.From, 10).toString()}&id=&date_type=false`} target="_blank" className="bg-gray-500 rounded-full px-2 py-1 text-xs flex items-center space-x-2">
                        <p>Bookings</p>
                        <div className="text-xs text-white bg-gray-800 rounded px-0.5">{callStatus?.user?._count?.booking}</div>
                    </a>
                    <a href={`/backoffice/crm`} target="_blank" className="bg-gray-500 rounded-full px-2 py-1 text-xs">
                        Open CRM
                    </a> 
                    {/* <div className="bg-gray-500 rounded-full px-2 py-1 text-xs">
                        History
                    </div>
                    <div className="bg-gray-500 rounded-full px-2 py-1 text-xs">
                        Create Lead
                    </div>
                    <div className="bg-gray-500 rounded-full px-2 py-1 text-xs">
                        Add Note
                    </div> */}
                </div>
            </div>
        </div> : null
    } else {
        return null
    }
}