export function isBlogger(user) {
    return !!(user?.roles?.includes('ROLE_ADMIN') || user?.roles?.includes('ROLE_MOD') || user?.roles?.includes('ROLE_BLOGGER'))
}

export function isLister(user) {
    return !!(user?.roles?.includes('ROLE_ADMIN') || user?.roles?.includes('ROLE_MOD') || user?.roles?.includes('ROLE_LISTER'))
}

export function isCallAgent(user) {
    return !!(user?.roles?.includes('ROLE_ADMIN') || user?.roles?.includes('ROLE_MOD') || user?.roles?.includes('ROLE_CALL_AGENT'))
}

export function isMod(user) {
    return !!(user?.roles?.includes('ROLE_ADMIN') || user?.roles?.includes('ROLE_MOD'))
}

export function isAdmin(user) {
    return !!user?.roles?.includes('ROLE_ADMIN')
}

export function isSuperAdmin(user) {
    return !!user?.roles?.includes('ROLE_SUPERADMIN')
}

export function isTeamMember(user) {
    return isCallAgent(user) || isLister(user) || isBlogger(user)
}