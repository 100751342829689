import { useEffect } from 'react';
import Script from 'next/script'
import NProgress from "../components/progress";

import { SWRConfig } from 'swr'

//Global Styles
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../components/theme';
import '../styles/globalstyles.css'
import { ContextProvider } from '../components/context';
import { Poppins, Caveat } from 'next/font/google'
import Popup from 'components/popup';
import * as Sentry from "@sentry/react";
import Custom500 from './500';
import { GoogleTagManager } from '@next/third-parties/google'
import { useRouter } from 'next/router';

const nunito = Poppins({
    weight: ['400', '500', '600', '700', '900'],
    subsets: ['latin']
})

const MyApp = ({ Component, pageProps }) => {
    const router = useRouter();

    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    return <>
        <Sentry.ErrorBoundary fallback={<Custom500 />} showDialog>
            <ThemeProvider theme={theme}>
                <ContextProvider>
                    <SWRConfig
                        value={{
                            refreshInterval: 0,
                            fetcher: (...args) => fetch(...args).then(res => res.json())
                        }}
                    >
                        <NProgress />
                        <Component {...pageProps} />
                        <Popup />
                    </SWRConfig>
                </ContextProvider>
            </ThemeProvider>
        </Sentry.ErrorBoundary>
        {process.env.NODE_ENV === 'production' ? <>
            <GoogleTagManager gtmId="GTM-5L5XM8H" />
        </> : null}
        <style jsx global>{`
        html {
            --font-poppins: ${nunito.style.fontFamily};
        }
      `}</style>
    </>
}

export default MyApp
