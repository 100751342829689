import React, { useContext, useEffect } from "react";
import { useRouter } from "next/router";
import dynamic from 'next/dynamic'
import { Context } from "./context";
import useUser from "lib/useUser";

const LinearProgress = dynamic(() => import('@material-ui/core/LinearProgress'))

function NProgress() {
    const [progress, setProgress] = React.useState(false);
    const context = useContext(Context)
    const router = useRouter();
    const { user } = useUser()

    useEffect(() => {
        router.events.on('routeChangeStart', () => {
            setProgress(true)
        })
        router.events.on('routeChangeComplete', () => {
            if (progress && (context.state.history.length === 0 || router.asPath !== context.state.history[context.state.history.length - 1])) {
                console.log(router.asPath)
                setTimeout(() => {
                    context.dispatch({ type: 'historyPush', route: router.asPath })
                }, 500)
            }
            setProgress(false)
        })
        router.events.on('routeChangeError', () => {
            console.log('Route Change Error!')
        })
    }, [])

    return <>
        {progress ? <LinearProgress className="nprogress" /> : null}
        {user?.isLoggedIn ? <img src={`${process.env.NEXT_PUBLIC_CDN}assets/icons/heart_animation.png`} className="hidden" /> : null}
    </>
}

export default NProgress;
