// utils/socket.js
import { io } from 'socket.io-client';

let socket;

export const getSocket = async () => {
  if (!socket) {
    await fetch('/api/socket');
    socket = io(); // Initialize the socket connection
    console.log('New socket connection initialized');
  }
  return socket;
};
